import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Image from './Image';

const imageWithUrlsPropertyTypes = {
  alt: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  objectFit: PropTypes.string,
  srcSet: PropTypes.string.isRequired,
  preload: PropTypes.bool,
  finalFetchpriority: PropTypes.string,
  placeholderFetchpriority: PropTypes.string,
  placeholderLoadStrategy: PropTypes.string,
  padding: PropTypes.string,
  disableRoundedCorners: PropTypes.bool,
};

type ImageWithUrlsProperties = InferProps<typeof imageWithUrlsPropertyTypes>;

const allEqual = (array: unknown[]) => array
  .every((value) => value === array[0]);

const ImageWithUrls = ({
  imageUrls,
  alt,
  width,
  height,
  sizes,
  objectFit,
  padding,
  disableRoundedCorners,
  srcSet,
  ...rest
} : ImageWithUrlsProperties) => {
  const hasOneFormat = allEqual(imageUrls);

  return (
    <Image
      placeholder={imageUrls[0]}
      src={imageUrls[1]}
      srcSet={hasOneFormat ? undefined : srcSet}
      alt={alt}
      width={width ?? '100%'}
      height={height ?? 'auto'}
      objectFit={objectFit ?? 'cover'}
      sizes={hasOneFormat ? undefined : sizes}
      padding={padding}
      disableRoundedCorners={disableRoundedCorners}
      {...rest}
    />
  );
};

ImageWithUrls.propTypes = imageWithUrlsPropertyTypes;

export default ImageWithUrls;
