import React from 'react';
import Typography, {
  TypographyProps as MUITypographyProperties,
} from '@mui/material/Typography';

export type TypographyProperties =
  Component.Properties<MUITypographyProperties>;

const MaterialUITypography = ({
  align = 'inherit',
  children,
  tag,
  className,
  noWrap = false,
  variant,
  color = 'textPrimary',
}: TypographyProperties) => (
  <Typography
    align={align}
    // @ts-expect-error component is mapped by variant
    component={tag}
    className={className}
    noWrap={noWrap}
    variant={variant}
    color={color}
  >
    {children}
  </Typography>
);

export default MaterialUITypography;
