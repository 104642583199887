import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../lib/messages.mjs';

const TextLoader = () => {
  const intl = useIntl();

  return (
    <span className="text-loader">{intl.formatMessage(messages.loading)}</span>
  );
};

export default TextLoader;
