import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';
import { Theme } from '@emotion/react';
import Subtitle from '../Typography/SubTitle';
import { topRoundedCorners } from '../RoundedCorners';

const labelPropertyTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

type LabelProperties = InferProps<typeof labelPropertyTypes>;

const StyledSubtitle = styled(Subtitle)<Theme>`
  && {
    position: absolute;
    bottom: 0;
    margin: 0;
    min-width: 147px;
    height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
    background-color: ${
  (properties) => properties.theme.palette?.primaryFallback.main
};
  background-color: ${
  (properties) => properties.theme.palette?.primary.main
};
    ${topRoundedCorners};
    & > span {
      padding: 0 8px;
    }
    & > span > span {
      padding: 0;
    }
  }
`;

const Label = (
  {
    children,
    className,
  }: LabelProperties,
) => (
  <StyledSubtitle
    className={className}
  >
    <span>{children}</span>
  </StyledSubtitle>
);

Label.propTypes = {
  /** Sets the content */
  children: PropTypes.node,
  /** Sets the class name */
  className: PropTypes.string,
  /** Sets a predefined style for headers */
};

export default Label;
