import messages from './messages.mjs';

const slash = /\//g;

const getCategoryTitle = (category, hasLocalNews, intl) => category
  && category.title
  && (hasLocalNews
    ? category.title
    : intl
      .formatMessage(
        messages[
          `category.${category.path.replaceAll(slash, '.')}`
        ],
      )
  );

export default getCategoryTitle;
