import dayjs from 'dayjs';
import getCategoryParameters from './getCategoryParameters';

export default function getArticlesParameters(article) {
  const categoryParameters = getCategoryParameters(article.category);

  return {
    ...categoryParameters,
    slug: article.slug || null,
    insertedAt: dayjs(article.insertedAt).format('YYYYMMDD'),
    id: article.id,
  };
}
