import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  useIntl,
  FormattedDate as IntlFormattedDate,
  FormattedRelativeTime,
} from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import TextLoader from './Loader/TextLoader';
import formatDateWithLocale from '../lib/formatDateWithLocale';

dayjs.extend(relativeTime);

const propertyTypes = {
  value: PropTypes.string,
};

const incrementableUnits = new Set([
  'second',
  'minute',
  'hour',
]);

type FormattedDateProperties = InferProps<typeof propertyTypes>;

const FormattedDate = ({ value } : FormattedDateProperties) => {
  const [postedRecently, setPostedRecently] = useState<boolean | null>(null);
  const { locale } = useIntl();

  const dayJsValue = dayjs(value);
  const fullDateTime = formatDateWithLocale(
    dayJsValue.toDate(),
    locale,
    {
      dateStyle: 'long',
      timeStyle: 'long',
      timeZone: undefined,
    },
  );

  useEffect(() => {
    if (value) {
      const dayAgo = dayjs().subtract(1, 'day');
      setPostedRecently(dayJsValue.isAfter(dayAgo));
    }
  }, [value, dayJsValue]);

  if (typeof value !== 'string') {
    return null;
  }

  if (typeof postedRecently !== 'boolean') {
    return <TextLoader />;
  }

  if (postedRecently) {
    const { value: relativeValue, unit } = selectUnit(dayJsValue.toDate());

    return (
      <span title={fullDateTime}>
        <FormattedRelativeTime
          value={relativeValue}
          unit={unit}
          updateIntervalInSeconds={incrementableUnits.has(unit) ? 1 : undefined}
        />
      </span>
    );
  }

  return (
    <span title={fullDateTime}>
      <IntlFormattedDate
        value={value}
        year="numeric"
        month="short"
        day="numeric"
      />
    </span>
  );
};

FormattedDate.propTypes = propertyTypes;

export default FormattedDate;
