import invert from 'lodash/invert';

const categoryParameters = {
  'новости/мода': 'novostee/moda',
  'новости/ритейл': 'novostee/reetyeil',
  'новости/бизнес': 'novostee/beeznyes',
  'новости/культура': 'novostee/kulstura',
  'новости/личности': 'novostee/leechnostee',
  'новости/выставки': 'novostee/vistavkee',
  новости: 'novostee',
  'новости/видео': 'novostee/veedyeo',
  'press/мода': 'press/moda',
  'press/ритейл': 'press/reetyeil',
  'press/бизнес': 'press/beeznyes',
  'press/личности': 'press/leechnostee',
  'press/культура': 'press/kulstura',
  'press/исследования': 'press/eesslyedovaneeya',
  'press/знаменитости': 'press/znamyeneetostee',
  'новости/podcast': 'novostee/podcast',
};
const categoryPaths = invert(categoryParameters);

export const translateCategoryPath = (categoryPath) => categoryParameters[
  categoryPath] || categoryPath;

export const translateCategoryParameter = (categoryPath) => categoryPaths[
  categoryPath] || categoryPath;
