import React from 'react';
import PropTypes from 'prop-types';
import MaterialUITypography from './Typography';

const Subtitle = ({
  children, tag, className, variant,
}) => (
  <MaterialUITypography
    tag={tag}
    className={className}
    variant={variant}
    color="textSecondary"
  >
    {children}
  </MaterialUITypography>
);

Subtitle.defaultProps = {
  children: undefined,
  tag: 'span',
  className: undefined,
  variant: 'subtitle1',
};

Subtitle.propTypes = {
  /** Sets the content */
  children: PropTypes.node,
  /** Change the default html tag */
  tag: PropTypes.string,
  /** Sets the classname */
  className: PropTypes.string,
  /**  Applies the theme typography styles * */
  variant: PropTypes.string,
};

export default Subtitle;
